import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { CourseRegistrationSetupTypes } from "./types";
import {
	createCourseRegistrationSetupSuccess,
	createCourseRegistrationSetupFailure,
	getAllCourseRegistrationSetupsSuccess,
	getAllCourseRegistrationSetupsFailure,
	notifyStudentsSuccess,
	notifyStudentsFailure,
	getRegistrationCoursesSuccess,
	getRegistrationCoursesFailure,
	deleteRegistrationSetupCourseSuccess,
	deleteRegistrationSetupCourseFailure,
	addCoursesSuccess,
	addCoursesFailure,
} from "./actions";
import { callApiPost, callApiGet, callApiDelete } from "../../utils/api";

function* createCourseRegistrationSetupCall({ payload }: { type: string; payload: any }) {
	try {
		const res = yield call(callApiPost, "course-registration/setups", payload);
		yield put(createCourseRegistrationSetupSuccess(res.data));
	} catch (err: any) {
		yield put(createCourseRegistrationSetupFailure(err.response.data));
	}
}

function* getAllCourseRegistrationSetups({ payload }: { type: string; payload: any }) {
	try {
		const res = yield call(callApiGet, `course-registration/setups/school/${payload.school_id}`);
		yield put(getAllCourseRegistrationSetupsSuccess(res.data));
	} catch (err: any) {
		yield put(getAllCourseRegistrationSetupsFailure(err.response.data));
	}
}

function* notifyStudents({ payload }: { type: string; payload: any }) {
	let url = "";

	if (payload.id != "") {
		url = `course-registration/notify?school_id=${payload.school_id}&academic_year=${payload.academic_year}&semester_id=${payload.semester}&id=${payload.id}`;
	} else {
		url = `course-registration/notify?school_id=${payload.school_id}&academic_year=${payload.academic_year}&semester_id=${payload.semester}`;
	}

	try {
		const res = yield call(callApiGet, url);
		yield put(notifyStudentsSuccess(res.data));
	} catch (err: any) {
		yield put(notifyStudentsFailure(err.response.data));
	}
}

function* getRegistrationSetupCourses({ payload }: any) {
	try {
		const res = yield call(callApiGet, `course-registration/setups/${payload.id}/courses`);
		yield put(getRegistrationCoursesSuccess(res.data));
	} catch (err: any) {
		yield put(getRegistrationCoursesFailure(err.response.data));
	}
}

function* deleteRegistrationSetupCourse({ payload }: any) {
	try {
		const res = yield call(callApiDelete, `course-registration/setups/courses`, payload.id);
		yield put(deleteRegistrationSetupCourseSuccess(res.data));
	} catch (err: any) {
		yield put(deleteRegistrationSetupCourseFailure(err.response.data));
	}
}

function* addCourses({ payload }: any) {
	try {
		const res = yield call(callApiPost, "course-registration/setups/add-courses", payload);
		yield put(addCoursesSuccess(res.data));
	} catch (err: any) {
		yield put(addCoursesFailure(err.response.data));
	}
}

function* watchCreateCourseRegistrationSetup() {
	yield takeEvery(CourseRegistrationSetupTypes.CREATE_COURSE_REGISTRATION_SETUP, createCourseRegistrationSetupCall);
}

function* watchGetAllCourseRegistrationSetups() {
	yield takeEvery(CourseRegistrationSetupTypes.GET_ALL_COURSE_REGISTRATION_SETUPS, getAllCourseRegistrationSetups);
}

function* watchNotifyStudents() {
	yield takeEvery(CourseRegistrationSetupTypes.NOTIFY_STUDENTS, notifyStudents);
}

function* watchGetRegistrationCourses() {
	yield takeEvery(CourseRegistrationSetupTypes.GET_REGISTRATION_SETUP_COURSES_REQUEST, getRegistrationSetupCourses);
}

function* watchDeleteRegistrationSetupCourse() {
	yield takeEvery(CourseRegistrationSetupTypes.ADD_COURSES_REQUEST, addCourses);
}

function* watchAddCourses() {
	yield takeEvery(
		CourseRegistrationSetupTypes.DELETE_REGISTRATION_SETUP_COURSES_REQUEST,
		deleteRegistrationSetupCourse
	);
}

function* CourseRegistrationSetupsSaga() {
	yield all([
		fork(watchCreateCourseRegistrationSetup),
		fork(watchGetAllCourseRegistrationSetups),
		fork(watchNotifyStudents),
		fork(watchGetRegistrationCourses),
		fork(watchDeleteRegistrationSetupCourse),
		fork(watchAddCourses),
	]);
}

export { CourseRegistrationSetupsSaga };
