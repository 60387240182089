import React, { useState } from "react";
import { CourseRegistrationCourse } from "../../interfaces/CourseRegistrationSetup";
import { Button, Col, Divider, Form, Modal, Popconfirm, Row, Table, Tag, message } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { AppDispatch } from "../../helpers/appDispatch";
import { useDispatch } from "react-redux";
import {
	addCoursesRequest,
	clearBooleanStates,
	deleteRegistrationSetupCourseRequest,
	deleteRegistrationSetupCourseSuccess,
} from "../../store/course-registration-setup";
import { appSelector } from "../../helpers/appSelector";
import { constants } from "../../helpers/constants";
import Courses from "./setup-steps/Courses";
import { Course } from "../../interfaces";

interface Props {
	courseRegistrationCourses: CourseRegistrationCourse[];
	isSubmittingCoursesRequest: boolean;
	courses: Course[];
	courseRegistrationSetupId: number;
}

interface DataSourceProps {
	key: number;
	id: number;
	course_name: string | undefined;
	type?: string;
}

export const CourseRegistrationCoursesDrawer: React.FC<Props> = ({
	courseRegistrationCourses,
	isSubmittingCoursesRequest,
	courses,
	courseRegistrationSetupId,
}) => {
	const dispatch: AppDispatch = useDispatch();
	const {
		isDeletingRegistrationCourse,
		deleteRegistrationCourseFailure,
		error,
		page,
		isSubmittingAddCoursesRequest,
	} = appSelector((state) => state.courseRegistrationSetupStore);
	const [courseId, setCourseId] = useState<number>();

	const [isModalOpen, setIsModalOpen] = useState(false);

	const [selectedCourses, setSelectedCourses] = useState<[React.Key, "string"]>();

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const handleOnConfirm = (id?: number) => {
		const payload = {
			id: id,
		};
		dispatch(deleteRegistrationSetupCourseRequest(payload));
		setCourseId(id);
	};

	const handleOnSubmit = (id: number) => {
		const payload = {
			courses: selectedCourses,
			course_registration_definitions_id: id,
		};
		dispatch(addCoursesRequest(payload));
	};

	if (deleteRegistrationCourseFailure && page === constants.courseRegistrationSetups) {
		message.error(error["message"]);
		dispatch(clearBooleanStates());
	}

	const columns: any = [
		{
			title: "Course",
			dataIndex: "course_name",
			key: "course_name",
		},
		{
			title: "Course Type",
			dataIndex: "type",
			key: "type",
			render: (type: string) => {
				let color = "";
				if (type === "core") {
					color = "green";
				} else {
					color = "blue";
				}

				return <Tag color={color}>{type.toUpperCase()}</Tag>;
			},
		},
		{
			title: "Action",
			dataIndex: "",
			key: "x",
			align: "center",
			render: (CourseRegistrationCourse: CourseRegistrationCourse) => {
				return (
					<Popconfirm
						title={
							"Remove from Programme Setup? This will remove it from students' registered courses as well"
						}
						placement="bottomRight"
						arrowPointAtCenter={false}
						onConfirm={() => handleOnConfirm(CourseRegistrationCourse.id)}
					>
						<Button
							loading={isDeletingRegistrationCourse && CourseRegistrationCourse.id == courseId}
							type="text"
							danger
							icon={<DeleteOutlined />}
						></Button>
					</Popconfirm>
				);
			},
		},
	];

	const dataSource: DataSourceProps[] = [];
	courseRegistrationCourses.forEach((course) => {
		return dataSource.push({
			id: course.id,
			course_name: course.course?.course_code + " " + course.course?.course,
			type: course.course_type,
			key: course.id,
		});
	});

	return (
		<>
			<div id="courses-list">
				<div className="pane-container">
					<div className="filter">
						<Button type="primary" onClick={showModal} icon={<PlusOutlined />}>
							Add Course
						</Button>
					</div>
				</div>
				<Divider />
				<Table dataSource={dataSource} columns={columns} bordered loading={isSubmittingCoursesRequest} />
				<Modal
					title="Add Course"
					open={isModalOpen}
					onOk={() => handleOnSubmit(courseRegistrationSetupId)}
					onCancel={handleCancel}
					confirmLoading={isSubmittingAddCoursesRequest}
				>
					<Courses
						courses={courses}
						selectedCourses={selectedCourses}
						setSelectedCourses={setSelectedCourses}
					/>
				</Modal>
			</div>
		</>
	);
};
