import React, { useState } from "react";
import { Row, Divider, Button, message, Form, Space, Popconfirm } from "antd";
import { MessageOutlined, PhoneFilled, PlusOutlined, QuestionCircleOutlined, SendOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../helpers/appDispatch";
import { appSelector } from "../helpers/appSelector";

import { constants } from "../helpers/constants";
import { messages } from "../helpers/messages";
import { CourseRegistrationSetupForm } from "../components/course-registrations-setup/CourseRegistrationSetupForm";
import { createCourseRegistrationSetup, notifyStudentsRequest } from "../store/course-registration-setup/actions";

const CourseRegistrationsSetups = () => {
	const dispatch: AppDispatch = useDispatch();
	const courseRegistrationSetupStore = appSelector((state) => state.courseRegistrationSetupStore);
	const { programmes } = appSelector((state) => state.programmesStore);
	const { courses } = appSelector((state) => state.academicsStore);
	const { school } = appSelector((state) => state.schoolStore);
	const [showTransferFormModal, setShowTransferFormModal] = useState(false);
	const [form] = Form.useForm();

	const { failure, page, error, success, isSubmitting } = courseRegistrationSetupStore;

	const toggleModal = () => {
		setShowTransferFormModal(!showTransferFormModal);
	};

	const onSubmit = (values) => {
		dispatch(createCourseRegistrationSetup(values));
	};

	if (success && page == constants.courseRegistrationSetups) {
		message.success(messages.courseRegistrationSetupSuccess);
		form.resetFields();
	}

	if (failure && page == constants.courseRegistrationSetups) {
		message.error(error.message, 5);
	}

	const onPopConfirm = () => {
		const payload = {
			school_id: school?.id,
			semester: school?.semester,
			academic_year: school?.academic_year,
		};
		dispatch(notifyStudentsRequest(payload));
	};

	return (
		<>
			<Row>
				<div className="pane-container">
					<div className="filter">
						<Space>
							<Popconfirm
								title="This will send an SMS to all students. Are you sure you want to continue?"
								icon={<QuestionCircleOutlined />}
								onConfirm={onPopConfirm}
								placement="bottom"
							>
								<Button type="ghost" danger icon={<PhoneFilled />} onClick={() => {}}>
									Notify Students
								</Button>
							</Popconfirm>

							<Button type="primary" icon={<PlusOutlined />} onClick={() => toggleModal()}>
								Setup Course Registration
							</Button>
						</Space>
					</div>
				</div>
				<Divider />
				<CourseRegistrationSetupForm
					isSubmitting={isSubmitting}
					form={form}
					onSubmit={onSubmit}
					programmes={programmes}
					showTransferFormModal={showTransferFormModal}
					toggleModal={toggleModal}
					courses={courses}
				/>
			</Row>
			{/* {content} */}
		</>
	);
};

export default CourseRegistrationsSetups;
