import { Col, Row, Table, Space, Radio, RadioChangeEvent } from "antd";
import React, { Key, useEffect, useState } from "react";
import { Course } from "../../../interfaces";
import { ColumnsType } from "antd/es/table";
import Search from "antd/es/input/Search";

interface StepTwoProps {
	courses: Course[];
	selectedCourses: [Key, "string"] | undefined;
	setSelectedCourses: (values) => void;
}

interface TableDataType {
	key?: number;
	course?: string;
	id?: number;
}

const Courses: React.FC<StepTwoProps> = ({ courses, selectedCourses, setSelectedCourses }) => {
	const [filteredCourses, setFilteredCourses] = useState<Course[]>();
	const [selectedTypes, setSelectedTypes] = useState<Record<React.Key, string>>({});
	const [selectedRowWithKeys, setSelectedRowWithKeys] = useState<React.Key[]>([]);

	useEffect(() => {
		setFilteredCourses(courses);
	}, [courses]);

	const courseTypesOptions = [
		{ label: "Core", value: "core" },
		{ label: "Electtive", value: "elective" },
	];

	const columns: ColumnsType<TableDataType> = [
		{
			title: "",
			dataIndex: "",
			key: "id",
		},
		{
			title: "Course",
			dataIndex: "course",
			key: "course",
		},
		{
			title: "Choose Type",
			dataIndex: "type",
			key: "type",
			render: (is_elective, data) => {
				return (
					<Space>
						<Radio.Group
							options={courseTypesOptions}
							onChange={(event) => handleOnCourseTypeChange(event, data.id)}
							disabled={!selectedRowWithKeys.includes(data.id as Key)}
							defaultValue="core"
						/>
					</Space>
				);
			},
		},
	];

	const handleOnCourseTypeChange = (event: RadioChangeEvent, courseId: any) => {
		setSelectedTypes((prevSelectedTypes) => ({
			...prevSelectedTypes,
			[courseId]: event.target.value,
		}));

		setSelectedCourses((prevSelectedCourses) => {
			const courseIndex = prevSelectedCourses.findIndex(
				(course: { courseId: any }) => course.courseId === courseId
			);

			if (courseIndex !== -1) {
				return prevSelectedCourses.map((course) =>
					course.courseId === courseId ? { ...course, type: event.target.value } : course
				);
			} else {
				return [...prevSelectedCourses, { courseId, type: event.target.value }];
			}
		});
	};

	const dataSource: TableDataType[] = [];

	filteredCourses?.map((course) => {
		return dataSource.push({
			key: course.id,
			id: course.id,
			course: course.course,
		});
	});

	const rowSelection = {
		selectedCourses,
		onChange: (
			selectedRowKeys: React.Key[]
			// selectedRows: TableDataType[],
			// selectedKey: any
		) => {
			setSelectedRowWithKeys(selectedRowKeys);
			const updatedSelectedCourses = selectedRowKeys.map((courseId) => {
				return {
					courseId,
					type: selectedTypes[courseId] || "core", // Default to "core" if not selected
				};
			});
			setSelectedCourses(updatedSelectedCourses);
		},
		preserveSelectedRowKeys: true,
		onSelect: (record, selected, selectedRows) => {
			if (selected) {
				setSelectedTypes((prevSelectedTypes) => ({
					...prevSelectedTypes,
					[record.id]: "core", // Default to "core"
				}));
			} else {
				setSelectedTypes((prevSelectedTypes) => {
					const { [record.id]: _, ...rest } = prevSelectedTypes;
					return rest;
				});
			}
		},
	};

	const onSearch = (event: React.KeyboardEvent) => {
		// @ts-ignore
		handleCourseFilter(event.target.value);
	};

	const handleCourseFilter = (value: string) => {
		const filteredCourses = courses.filter((courses) => courses.course.toLowerCase().includes(value.toLowerCase()));
		setFilteredCourses(filteredCourses);
	};

	return (
		<>
			<Row>
				<Col span={24} md={24} sm={24} xs={24}>
					<Search placeholder="Search Course" onKeyUp={(event) => onSearch(event)} />
				</Col>
			</Row>
			<Row gutter={10}>
				<Col span={24} md={24} sm={24} xs={24}>
					<Table size={"small"} dataSource={dataSource} columns={columns} rowSelection={rowSelection} />
				</Col>
			</Row>
		</>
	);
};

export default Courses;
