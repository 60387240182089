import React, { useState } from "react";
import { CourseRegistrationCourse } from "../../interfaces/CourseRegistrationSetup";
import { Button, Col, Divider, Form, Modal, Popconfirm, Row, Table, Tag, message } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { AppDispatch } from "../../helpers/appDispatch";
import { useDispatch } from "react-redux";
import {
	addCoursesRequest,
	clearBooleanStates,
	deleteRegistrationSetupCourseRequest,
	deleteRegistrationSetupCourseSuccess,
} from "../../store/course-registration-setup";
import { appSelector } from "../../helpers/appSelector";
import { constants } from "../../helpers/constants";
import { Course } from "../../interfaces";
import Courses from "../course-registrations-setup/setup-steps/Courses";
import { CourseRegistration, RegisteredCourse } from "../../interfaces/CourseRegistration";

interface Props {
	selectedCourseRegistration: CourseRegistration | undefined;
	isSubmitting: boolean;
}

interface DataSourceProps {
	key: number;
	id: number;
	course_name: string | undefined;
}

export const CourseRegistrationCoursesDrawer: React.FC<Props> = ({ selectedCourseRegistration, isSubmitting }) => {
	const dispatch: AppDispatch = useDispatch();
	const {
		isDeletingRegistrationCourse,
		deleteRegistrationCourseFailure,
		error,
		page,
		isSubmittingAddCoursesRequest,
	} = appSelector((state) => state.courseRegistrationSetupStore);
	const [courseId, setCourseId] = useState<number>();

	const [isModalOpen, setIsModalOpen] = useState(false);

	const [selectedCourses, setSelectedCourses] = useState<[React.Key, "string"]>();

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const handleOnConfirm = (id?: any) => {
		const payload = {
			id: id,
		};
		dispatch(deleteRegistrationSetupCourseRequest(payload));
		setCourseId(id);
	};

	const handleOnSubmit = (id: any) => {
		const payload = {
			courses: selectedCourses,
			course_registration_definitions_id: id,
		};
		dispatch(addCoursesRequest(payload));
	};

	if (deleteRegistrationCourseFailure && page === constants.courseRegistrationSetups) {
		message.error(error["message"]);
		dispatch(clearBooleanStates());
	}

	const columns: any = [
		{
			title: "Course",
			dataIndex: "course_name",
			key: "course_name",
		},
		// {
		// 	title: "Action",
		// 	dataIndex: "",
		// 	key: "x",
		// 	align: "center",
		// 	render: (registeredCourse: RegisteredCourse) => {
		// 		return (
		// 			<Popconfirm
		// 				title={
		// 					"Remove from Programme Setup? This will remove it from students' registered courses as well"
		// 				}
		// 				placement="bottomRight"
		// 				arrowPointAtCenter={false}
		// 				onConfirm={() => handleOnConfirm(registeredCourse.id)}
		// 			>
		// 				<Button
		// 					loading={isDeletingRegistrationCourse && registeredCourse.id == courseId}
		// 					type="text"
		// 					danger
		// 					icon={<DeleteOutlined />}
		// 				></Button>
		// 			</Popconfirm>
		// 		);
		// 	},
		// },
	];

	const dataSource: DataSourceProps[] = [];
	selectedCourseRegistration?.course_registration_details.forEach((course) => {
		return dataSource.push({
			id: course.id,
			course_name: course.course?.course_code + " " + course.course?.course,
			key: course.id,
		});
	});

	return (
		<>
			<div id="courses-list">
				{/* <div className="pane-container">
					<div className="filter">
						<Button type="primary" onClick={showModal} icon={<PlusOutlined />}>
							Add Course
						</Button>
					</div>
				</div> */}
				<h2 className="">Course Registration Details</h2>
				<h3 style={{ marginBottom: 0 }}>Index Number: {selectedCourseRegistration?.index_number}</h3>
				<h4 style={{ marginBottom: 0 }}>Name: {selectedCourseRegistration?.student_name}</h4>
				<h5 style={{ marginBottom: 0 }}>
					Level {selectedCourseRegistration?.level} - {selectedCourseRegistration?.academic_year} Academic
					Year, Semester {selectedCourseRegistration?.semester}
				</h5>
				<Divider />
				<Table size="small" dataSource={dataSource} columns={columns} bordered loading={isSubmitting} />
			</div>
		</>
	);
};
