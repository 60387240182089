import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { callApiGet } from "../../utils/api";
import {
	getCourseRegistrationsFailure,
	getCourseRegistrationsSuccess,
	getRegisteredCoursesFailure,
	getRegisteredCoursesSuccess,
} from "./actions";
import { CourseRegistrationPayload, RegisteredCoursesPayload } from "../../interfaces/CourseRegistration";
import { CourseRegistrationTypes } from "./types";

function* getCourseRegistrations({ payload }: { type: string; payload: CourseRegistrationPayload }) {
	try {
		const response = yield call(
			callApiGet,
			`course-registration?school_id=${payload.school_id}&academic_year=${payload.academic_year}&semester=${payload.semester}`
		);
		yield put(getCourseRegistrationsSuccess(response.data));
	} catch (error: any) {
		yield put(getCourseRegistrationsFailure(error.response.data.message));
	}
}

function* getRegisteredCourses({ payload }: { type: string; payload: RegisteredCoursesPayload }) {
	try {
		const response = yield call(callApiGet, `course-registration/${payload.uuid}/courses`);
		yield put(getRegisteredCoursesSuccess(response.data));
	} catch (error: any) {
		yield put(getRegisteredCoursesFailure(error.response.data.message));
	}
}

function* watchGetAllCourseRegistrations() {
	yield takeEvery(CourseRegistrationTypes.GET_ALL_COURSE_REGISTRATIONS, getCourseRegistrations);
}

function* watchGetRegisteredCourses() {
	yield takeEvery(CourseRegistrationTypes.GET_REGISTERED_COURSES, getRegisteredCourses);
}

function* CourseRegistrationSaga() {
	yield all([fork(watchGetAllCourseRegistrations)]);
}

export { CourseRegistrationSaga };
