import { Reducer } from "redux";
import { CourseRegistrationState, CourseRegistrationTypes } from "./types";
import { constants } from "../../helpers/constants";
import { AuthActionTypes } from "../auth";

const initialState = {
	isSubmitting: false,
	isDeleting: false,
	loading: false,
	success: false,
	failure: false,
	error: null,
	deleteSuccess: false,
	courseRegistrations: [],
	registeredCourses: [],
	page: "",
};

const reducer: Reducer<CourseRegistrationState> = (state = initialState, action) => {
	switch (action.type) {
		case CourseRegistrationTypes.SUBMITTING:
			return {
				...state,
				isSubmitting: true,
				success: false,
				loading: true,
				error: null,
				failure: false,
			};
		case CourseRegistrationTypes.GET_ALL_COURSE_REGISTRATIONS:
			return {
				...state,
				isSubmitting: true,
				success: false,
				loading: true,
				error: null,
				failure: false,
			};
		case CourseRegistrationTypes.GET_ALL_COURSE_REGISTRATIONS_SUCCESS:
			return {
				...state,
				isSubmitting: false,
				success: true,
				loading: false,
				error: null,
				failure: false,
				page: constants.courseRegistration,
				courseRegistrations: action.payload,
			};
		case CourseRegistrationTypes.GET_ALL_COURSE_REGISTRATIONS_FAILURE:
			return {
				...state,
				isSubmitting: false,
				success: false,
				loading: false,
				error: action.payload,
				failure: true,
				page: constants.courseRegistration,
			};

		case CourseRegistrationTypes.GET_REGISTERED_COURSES:
			return {
				...state,
				isSubmitting: true,
				success: false,
				loading: true,
				error: null,
				failure: false,
			};
		case CourseRegistrationTypes.GET_REGISTERED_COURSES_SUCCESS:
			return {
				...state,
				isSubmitting: false,
				success: true,
				loading: false,
				error: null,
				failure: false,
				page: constants.courseRegistration,
				registeredCourses: action.payload,
			};
		case CourseRegistrationTypes.GET_REGISTERED_COURSES_FAILURE:
			return {
				...state,
				isSubmitting: false,
				success: false,
				loading: false,
				error: action.payload,
				failure: false,
				page: constants.courseRegistration,
			};
		case CourseRegistrationTypes.CLEAR_BOOLEAN_STATES:
			return {
				...state,
				isSubmitting: false,
				isDeleting: false,
				loading: false,
				success: false,
				failure: false,
				error: null,
			};
		case CourseRegistrationTypes.CLEAR_DATA:
			return {
				...state,
				courseRegistrations: [],
				registeredCourses: [],
				page: "",
			};

		case AuthActionTypes.DESTROY_STATES:
			return initialState;
		default:
			return state;
	}
};

export { reducer as CourseRegistrationReducer };
