import { CourseRegistration, RegisteredCourse } from "../../interfaces/CourseRegistration";

export enum CourseRegistrationTypes {
	SUBMITTING = "@@courseRegistration/SUBMITTING",
	GET_ALL_COURSE_REGISTRATIONS = "@@courseRegistration/GET_ALL_COURSE_REGISTRATIONS",
	GET_ALL_COURSE_REGISTRATIONS_SUCCESS = "@@courseRegistration/GET_ALL_COURSE_REGISTRATIONS_SUCCESS",
	GET_ALL_COURSE_REGISTRATIONS_FAILURE = "@@courseRegistration/GET_ALL_COURSE_REGISTRATIONS_FAILURE",
	DELETE_COURSE_REGISTRATION = "@@courseRegistration/DELETE_COURSE_REGISTRATION",
	DELETE_COURSE_REGISTRATION_SUCCESS = "@@courseRegistration/DELETE_COURSE_REGISTRATION_SUCCESS",
	DELETE_COURSE_REGISTRATION_FAILURE = "@@courseRegistration/DELETE_COURSE_REGISTRATION_FAILURE",
	GET_REGISTERED_COURSES = "@@courseRegistration/GET_REGISTERED_COURSES",
	GET_REGISTERED_COURSES_SUCCESS = "@@courseRegistration/GET_REGISTERED_COURSES_SUCCESS",
	GET_REGISTERED_COURSES_FAILURE = "@@courseRegistration/GET_REGISTERED_COURSES_FAILURE",
	CLEAR_BOOLEAN_STATES = "@@courseRegistration/CLEAR_BOOLEAN_STATES",
	CLEAR_DATA = "@@courseRegistration/CLEAR_DATA",
}

export type CourseRegistrationState = {
	readonly isSubmitting: boolean;
	readonly isDeleting: boolean;
	readonly loading: boolean;
	readonly success: boolean;
	readonly failure: boolean;
	readonly error: any;
	readonly deleteSuccess: boolean;
	readonly courseRegistrations: CourseRegistration[];
	readonly registeredCourses: RegisteredCourse[];
	readonly page: string;
};
