import { action } from "typesafe-actions";
import { CourseRegistrationTypes } from "./types";
import {
	CourseRegistration,
	CourseRegistrationPayload,
	RegisteredCourse,
	RegisteredCoursesPayload,
} from "../../interfaces/CourseRegistration";

export const getCourseRegistrations = (payload: CourseRegistrationPayload) => {
	return action(CourseRegistrationTypes.GET_ALL_COURSE_REGISTRATIONS, payload);
};

export const getCourseRegistrationsSuccess = (courseRegistrations: CourseRegistration[]) => {
	return action(CourseRegistrationTypes.GET_ALL_COURSE_REGISTRATIONS_SUCCESS, courseRegistrations);
};

export const getCourseRegistrationsFailure = (error: any) => {
	return action(CourseRegistrationTypes.GET_ALL_COURSE_REGISTRATIONS_FAILURE, error);
};

export const getRegisteredCourses = (payload: RegisteredCoursesPayload) => {
	return action(CourseRegistrationTypes.GET_REGISTERED_COURSES, payload);
};

export const getRegisteredCoursesSuccess = (registeredCourses: RegisteredCourse[]) => {
	return action(CourseRegistrationTypes.GET_REGISTERED_COURSES_SUCCESS, registeredCourses);
};

export const getRegisteredCoursesFailure = (error: any) => {
	return action(CourseRegistrationTypes.GET_REGISTERED_COURSES_FAILURE, error);
};
